import React, { useEffect, useState } from "react";
import Clock from "./clock";
import { BiLogoTelegram } from "react-icons/bi";
import { FaPlay } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { useWeb3Modal, useWeb3ModalAccount } from "@web3modal/ethers5/react";
import { WALLETCONTEXT } from "../contexts/walletContext";
import { constants, utils, ethers, errors, Signer } from "ethers";
import axios from "axios";
import toast from "react-hot-toast";
import { index_contract_address } from "../utils";
const Buy = () => {
  const { open } = useWeb3Modal();
  const { isConnected, address } = useWeb3ModalAccount();
  const {
    currentRound,
    getIndexContract,
    getUsdtContract,
    getProjectXContract,
    getProvider,
  } = WALLETCONTEXT();
  const [nextPrice, setNextPrice] = useState(0);
  const [activeToken, setActiveToken] = useState("usdt");
  const [currentPrice, setCurrentPrice] = useState(0);
  const [youPay, setYouPay] = useState("");
  const [youReceive, setYouRecieve] = useState(0);
  const [maticPrice, setMaticPrice] = useState(0);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (isConnected) {
      getData();
      getNextData();
    }
  }, [isConnected]);
  useEffect(() => {
    if (isConnected) {
      calculateTokens(youPay || 0);
    }
  }, [activeToken, isConnected]);
  useEffect(() => {
    const updateCountdowns = async () => {
      try {
        const response = await axios.get(
          "https://api.coingecko.com/api/v3/simple/price?ids=matic-network&vs_currencies=usd"
        );
        setMaticPrice(response.data["matic-network"].usd);
      } catch (error) {
        console.log(error);
      }
    };

    updateCountdowns();
    const interval = setInterval(updateCountdowns, 60000);

    return () => clearInterval(interval);
  }, []);
  const getData = async () => {
    try {
      const contract = await getIndexContract();
      let result = await contract.tokenSold();
      const tokenSold = utils.formatEther(result);
      const round = await contract.rounds(currentRound);
      const price = utils.formatEther(round.price);
      result = tokenSold / price;
      setCurrentPrice(price);
    } catch (error) {
      console.log(error.message);
    }
  };

  const getNextData = async () => {
    try {
      const contract = await getIndexContract();
      const round = await contract.rounds(currentRound + 1);
      const price = utils.formatEther(round.price);
      setNextPrice(parseFloat(1 / price).toFixed(5));
    } catch (error) {
      console.log(error.message);
    }
  };
  const calculateTokens = (value) => {
    if (value > 0) {
      const inputValue = value;
      const normalizedValue = inputValue ? String(Number(inputValue)) : "";
      setYouPay(normalizedValue);
      if (activeToken === "usdt") {
        setYouRecieve(currentPrice * value);
      } else {
        setYouRecieve(maticPrice * value * currentPrice);
      }
    }
  };
  const buyToken = () => {
    if (youPay <= 0) {
      toast.error("Please enter amount to buy.");
      return;
    }
    if (activeToken === "usdt") {
      buyByUsdt();
    } else {
      // buyByMatic();
    }
  };
  const buyByUsdt = async () => {
    try {
      setLoading(true);
      const usdtContract = await getUsdtContract();
      let allowance = await usdtContract.allowance(
        address,
        index_contract_address
      );
      allowance = String(allowance);
      // if (allowance === 0) {
      let value = constants.MaxUint256;
      const approve = await usdtContract.approve(index_contract_address, value);
      await approve.wait();
      // }
      const indexContract = await getIndexContract();
      const result = await indexContract.purchaseTokens(
        utils.parseEther(youPay.toString()),
        {
          gasLimit: "2100000",
        }
      );
      await result.wait();
      toast.success(`CONGRATULATION, YOU'RE PART OF SOMETHING BIG NOW!`);
      setYouPay("");
      setYouRecieve("");
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error.code === errors.CALL_EXCEPTION) {
        toast.error("Transaction reverted without a reason string");
      } else if (
        error.code === errors.INSUFFICIENT_FUNDS ||
        error.code === -32603
      ) {
        toast.error("Insufficient funds for the transaction");
      } else if (error.code === errors.NONCE_EXPIRED) {
        toast.error("Transaction nonce has expired");
      } else if (error.code === errors.REPLACEMENT_UNDERPRICED) {
        toast.error("Replacement transaction underpriced");
      } else if (error.code === errors.UNPREDICTABLE_GAS_LIMIT) {
        toast.error("Transfer amount exceeds balance");
      } else {
        toast.error("An error occurred during the transaction");
      }
      setLoading(false);
    }
  };

  // const buyByMatic = async () => {
  //   try {
  //     setLoading(true);
  //     const contract = await getProjectXContract();
  //     let allowance = await contract.allowance(address, address);
  //     allowance = String(allowance);
  //     if (allowance === 0) {
  //       const approve = await contract.approve(address, constants.MaxUint256);
  //       await approve.wait();
  //     }
  //     const result = await contract.transfer(
  //       address,
  //       utils.parseEther(youReceive.toString()),
  //       {
  //         gasLimit: "300000",
  //       }
  //     );
  //     await result.wait();
  //     toast.success(`CONGRATULATION, YOU'RE PART OF SOMETHING BIG NOW!`);
  //     setYouPay("");
  //     setYouRecieve("");
  //     setLoading(false);
  //   } catch (error) {
  //     console.log(error);
  //     if (error.code === errors.CALL_EXCEPTION) {
  //       toast.error("Transaction reverted without a reason string");
  //     } else if (error.code === errors.INSUFFICIENT_FUNDS) {
  //       toast.error("Insufficient funds for the transaction");
  //     } else if (error.code === errors.NONCE_EXPIRED) {
  //       toast.error("Transaction nonce has expired");
  //     } else if (error.code === errors.REPLACEMENT_UNDERPRICED) {
  //       toast.error("Replacement transaction underpriced");
  //     } else if (error.code === errors.UNPREDICTABLE_GAS_LIMIT) {
  //       toast.error("Transfer amount exceeds balance");
  //     } else {
  //       toast.error("An error occurred during the transaction");
  //     }
  //     setLoading(false);
  //   }
  // };
  return (
    <div className="grid grid-cols-12 lg:gap-x-10" name="buy" id="buy">
      <div className="col-span-12 lg:col-span-7 lg:flex lg:flex-col lg:items-end  lg:justify-start lg:w-max lg:mr-5 lg:ml-auto mb-10">
        <h1
          className="text-center text-6xl md:text-8xl uppercase font-bold lg:px-5 lg:text-right mb-5 break-words"
          style={{ fontFamily: "BebasNeueCyrillic" }}
        >
          <span style={{ fontFamily: "BebasNeueCyrillic" }}>
            Join the{" "}
            <span className="font-black text-[#02e4fb] font-black">X</span>
          </span>
          &nbsp;
          <br className="" />
          Revolution Now!
        </h1>
        <div className="flex flex-col lg:flex-row lg:flex-wrap justify-center items-center lg:justify-end lg:mr-5 gap-x-5 gap-y-3 w-full">
          <div className="flex w-max gap-x-5">
            <a
              href="https://0x0me.me/White-paper-Project-X-meme-V1.pdf"
              className="gradient-button border px-5 py-2 "
            >
              whitepaper
            </a>
            <a
              href="https://360l.ink/Project-X"
              className="gradient-button border px-5 py-2"
            >
              website
            </a>
          </div>
          <div></div>
          <div className="flex w-max gap-x-5">
            <a
              href="https://t.me/project0x0"
              className="border border-[#02e4fb] text-[#02e4fb] hover:border-[#ffffff] hover:text-[#ffffff] rounded-full p-3 flex items-center"
            >
              <BiLogoTelegram />
            </a>
            <a
              href="https://www.youtube.com/@ProjectXmeme"
              className="border border-[#02e4fb] text-[#02e4fb] hover:border-[#ffffff] hover:text-[#ffffff] rounded-full p-3 flex items-center"
            >
              <FaPlay />
            </a>
            <a
              href="https://x.com/Project0x0"
              className="border border-[#02e4fb] text-[#02e4fb] hover:border-[#ffffff] hover:text-[#ffffff] rounded-full p-3 flex items-center"
            >
              <FaTwitter />
            </a>
          </div>
        </div>
      </div>
      <div className="col-span-12 lg:col-span-5 border-gradient-buy border-4 px-5 py-5">
        <div className="border-gradient-buy-bg"></div>
        <h1 className="uppercase text-center font-semibold text-xl mb-5">
          BUY NOW BEFORE PRICE INCREASE!
        </h1>
        <div className="mb-5">
          <Clock currentRound={currentRound} />
        </div>
        <div className="flex justify-between items-center gap-y-5 mb-5">
          <div className="text-left">
            <h1 className="text-sm">
              Listing Price: <span>$0.00012</span>&nbsp;
              <span className="text-[#02e4fb]">+300%</span>
            </h1>
          </div>
          <div className="text-right">
            <h1 className="text-sm">
              Next Price: <span>{nextPrice}</span>&nbsp;
              {nextPrice === "0.00005" && (
                <span className="text-[#02e4fb]">+66%</span>
              )}
              {nextPrice === "0.00007" && (
                <span className="text-[#02e4fb]">+40%</span>
              )}
              {nextPrice === "0.0001" && (
                <span className="text-[#02e4fb]">+43%</span>
              )}
            </h1>
          </div>
        </div>
        <div className="mb-5">
          <ul className="btn-list currency__lists mb-5 flex gap-2">
            {/* <li>
              <button
                className={`btn-outline-gray outline-1 outline-[#282828] outline hover:outline-0 ${
                  activeToken === "matic" && "active"
                }`}
                onClick={() => setActiveToken("matic")}
              >
                <img
                  className="icon me-icon"
                  src="assets/img/icons/matic.webp"
                  alt=""
                />
                MATIC
              </button>
            </li> */}
            <li>
              <button
                className={`btn-outline-gray outline-1 outline-[#282828] outline hover:outline-0 ${
                  activeToken === "usdt" && "active"
                }`}
                onClick={() => setActiveToken("usdt")}
              >
                <img
                  className="icon me-icon"
                  src="assets/img/icons/usdt.webp"
                  alt=""
                />
                USDT
              </button>
            </li>
          </ul>
        </div>
        <div className="grid grid-cols-12 gap-y-5 mb-5 gap-x-5">
          <div className="col-span-12 md:col-span-6">
            <div className="relative flex items-center border rounded-md overflow-hidden">
              <input
                type="number"
                id="animated-input-you-pay"
                className="peer h-12 w-full px-3 border-none focus:outline-none placeholder-transparent text-sm bg-transparent"
                placeholder=" "
                min="0"
                value={youPay}
                onChange={(e) => calculateTokens(e.target.value)}
              />
              <label
                htmlFor="animated-input-you-pay"
                className="absolute left-3 top-0.5 text-gray-600 text-xs transition-all duration-300 peer-placeholder-shown:top-2 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-focus:top-0.5 peer-focus:text-xs peer-focus:text-blue-600"
              >
                You pay:
              </label>
              {!youPay && (
                <label
                  htmlFor="animated-input-you-pay"
                  className="absolute left-20 -top-3.5 text-gray-600 text-sm transition-all duration-300 peer-placeholder-shown:top-2 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-focus:-top-3.5 peer-focus:text-sm peer-focus:text-base peer-focus:opacity-0"
                >
                  0.00
                </label>
              )}
              <img
                className="icon me-icon absolute right-3"
                src="assets/img/icons/matic.webp"
                alt=""
              />
            </div>
          </div>
          <div className="col-span-12 md:col-span-6">
            <div className="relative flex items-center border rounded-md overflow-hidden">
              <input
                type="number"
                id="animated-input-you-pay"
                className="peer h-12 px-3 border-none focus:outline-none placeholder-transparent text-sm bg-transparent"
                placeholder=" "
                readOnly={"readOnly"}
                disabled
                value={parseFloat(youReceive).toFixed(2)}
                onChange={() => setYouRecieve(youReceive)}
              />
              <label
                htmlFor="animated-input-you-pay"
                className="absolute left-3 top-0.5 text-gray-600 text-xs transition-all duration-300 peer-placeholder-shown:top-2 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-focus:-top-0.5 peer-focus:text-xs peer-focus:text-blue-600"
              >
                You recieve:
              </label>

              <img
                className="icon me-icon absolute right-3"
                src="assets/img/icons/1.webp"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="mb-5">
          {!isConnected ? (
            <button
              onClick={() => open()}
              className="btn-outline-gray active w-full border-0 outline-0"
            >
              Connect Your Wallet
            </button>
          ) : (
            <button
              onClick={buyToken}
              className="btn-outline-gray active w-full border-0 outline-0"
              disabled={loading}
            >
              Buy Token Now
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
export default Buy;
